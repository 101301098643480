import { useRef, useEffect }                from 'react'

import { AnimatePresence }                  from 'framer-motion'
import useMeasure                           from 'react-use-measure'
import { _project }                         from 'state/store.projects'
import { _isometry }                        from 'state/store.isometry'
import { active_project }                   from 'state/store.global'
import { iso_free }                         from 'state/store.global'
import { iso_isrotating }                   from 'state/store.global'
import { getFocus, setFocus, useGet}        from 'state/jotai'
import { getIsoData, setIsoData }           from 'state/jotai.isometryDataHooks'
import { getProjectData }                   from 'state/jotai.projectDataHooks'

import { useBreak }                         from 'hooks/useBreak'

import { Space }                            from './Isometry.style'
import { Cursor }                           from './Isometry.Cursor'
import { createZppSetup }                   from './Isometry.ZoomPanPinch'
import { Canvas }                           from './Isometry.Canvas'
import { Center }                           from './Isometry.Center'
import { Tiny }                             from './Isometry.Tiny'
import { Svg }                              from './Isometry.Svg'
import { Controls }                         from './Isometry.Controls'
import { Measure }                          from './Isometry.Measure'
import { Recenter }                         from './isometry.Recenter'
import { Loading2 }                         from 'components/Loading2/Loading2'
import { ZoomPanPinch }                     from 'components/ZoomPanPinch/ZoomPanPinch'
import { Compas }                           from 'components/Compas/Compas'
import { ColorLegend }                      from 'components/ColorLegend/ColorLegend'
import { NoSnapSlider }                     from 'components/IsometryControls/NoSnapSlider'
import { Play }                             from 'components/IsometryRotation/Play'
import { SnapOnRotationEnd }                from 'components/IsometrySnapOnRotationEnd/SnapOnRotationEnd'
import { Status }                           from 'components/StatusPopup/StatusPopup'
import { Mode360 }                          from 'components/IsometryRotation/360'
import { drawer_animation_done }            from 'state/store.global'
import { Back }                             from './Isometry.Back'

export const Isometry = () => {
  
  const [measure, bounds] = useMeasure()

  const { pin, pid } = useGet(active_project)
  const showSVG      = useGet(drawer_animation_done)

  const mode    = true
  const tiny    = getIsoData('tiny', 'src')
  const frames  = getIsoData('frames')
  const percent = getIsoData('percent')
  const status  = getIsoData('status')
  const config  = getIsoData('config')
  const frame   = getIsoData('activeframe')
  const svg     = getIsoData('overlays', frame)
  const index   = getIsoData('snapindex')
  
  const units = getProjectData('units', 'Units')

  const setIndex = setIsoData('snapindex')
  const setFrame = setIsoData('activeframe')
  const setScale = setFocus(_isometry, 'activescale')

  const zppRef   = useRef()
  const canRef   = useRef()
  const dim      = { W: config?.IMAGE_W, H: config?.IMAGE_H }
  const point    = config?.CENTER
  const zppsetup = createZppSetup(bounds, zppRef, dim, setScale, mode)
  const rmode    = useGet(iso_free)
  const isrotate = useGet(iso_isrotating)

  const buttonsLabel = getFocus(_project, 'buttonsLabel')?.[pin]?.[pid] ?? []
  const isMobile     = useBreak('md_dn')
  if ( config && pid && pin >= 0 ) return (
    <>
      <Cursor>
        <Space d = {25} m = {120}/>
        <SnapOnRotationEnd {...{ frame, setFrame, config, setIndex, rmode, isrotate }} />
        <Measure ref = {measure}>
          <ZoomPanPinch {...{ ref:zppRef, ...zppsetup }}>
            <Tiny {...{ tiny, dim, status, percent }}/>
            <Canvas {...{ ref:canRef, frame, pid, status, dim, frames, config }} />
            <Center {...{ point }} />
            { !rmode && showSVG && <Svg {...{ svg, percent, units }} /> }
          </ZoomPanPinch>
        </Measure>
        <Space d = {180} m = {110}/>
        <Loading2 {...{ progress:percent, show:(status !== 'DONE' || !units) && frames.length > 1 }} />
         <AnimatePresence>
          { rmode && <>
            <NoSnapSlider {...{ frame, setFrame, config, frames, index, setIndex }}/>
            { isrotate && <Play {...{ frame, setFrame, config, setIndex }} /> }
          </> }
          </AnimatePresence>
          <AnimatePresence>
          { !rmode && <>
            <Status  {...{percent}}/>
            {/* <ColorLegend/> */}
            <Controls {...{ frame, setFrame, config, frames, index, setIndex }} />
          </> }
        </AnimatePresence>
        <Compas   frame = {frame} cfg     = {config} label = {buttonsLabel?.filter( btn => btn.UILabelProperty === 'Nordpil')[0]?.Label}  {...{percent}}/>
        <Recenter z     = {zppRef} bounds = {bounds} dim   = {dim}/>
        {/* { !isMobile && <Mode360/> } */}
        {/* { !isMobile && rmode && <Back/> } */}
      </Cursor>
    </>
  )
}