import { Navigate, useRoutes, Outlet }              from 'react-router-dom'
import { _bootstrap }                               from 'state/store.bootstrap'
import { useFocusGet }                              from 'state/jotai'

import { Root }                                     from 'pages/View.Root'
import { NoMatch }                                  from 'pages/View.NoMatch'
import { ViewFrontpage }                            from 'pages/View.Frontpage'
import { multi_collective, multi_single, standard } from 'pages/Views.constants'

import { useTranslation } from 'react-i18next'

export const useBuildRoutes = () => {

  const { i18n:{language} } = useTranslation()

  const type     = useFocusGet(_bootstrap, 'type')
  const start    = useFocusGet(_bootstrap, 'startpage')
  const projects = useFocusGet(_bootstrap, 'projectnames')
  
  const individual  = 
        type       == 'poly'
      ? projects.map(({ URLSafe }) => ({
          path    : URLSafe,
          element : <Outlet />,
          children: [...multi_single(language,start)],
        }))
      :  null

  return type === 'poly'
    ? [
      {
          path    : '/',
          element : <Root />,
          children: [
            { path: `:${language}`, element: <Navigate to={start} replace />, index: true },
            { path: `:${language}/frontpage`, element: <ViewFrontpage/> },
            { path: `:${language}/overview`, element: <Outlet />, children: [...multi_collective(language,start)] },
            ... individual,
            { path: '*', element: <NoMatch /> },
          ],
        },
      ] : [
        {
          path    : '/',
          element : <Root />,
          children: [
            { path: '', element: <Navigate to={start} replace />, index: true },
            ... standard(language, start),
            { path: '*', element: <NoMatch /> },
          ],
        },
      ]
}

export const Views = () => useRoutes( useBuildRoutes() )