import styled from 'styled-components/macro'

export const Wrap = styled.div`
  /* border:1px solid black; */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: fit-content;
  cursor: pointer;
  gap: 2px;
  overflow: visible;
  min-width:${({width})=>width};
  max-width:${({width})=>width};
  position:relative;
  transition:transform 0.3s;
`
export const Text = styled.p`
  user-select: none;
  color: ${({ color }) => color};
  font-size: ${({ fontSize }) => fontSize};
  font-weight: normal;
  display: ${({show}) => show ? 'unset' : 'none' };
  /* border:1px solid black; */
`
export const Svg = styled.svg`
  /* border:1px solid black; */
  user-select: none;
  position:relative;
`
export const Path = styled.path`
  user-select: none;
`
export const Circle = styled.div`
  border-radius: 100%;
  background-color: #7B2C1C;
  color: #FFFFFF;
  width: 14px;
  height: 14px;
  position: absolute;
  left: ${({ x }) => x}px;
  top: ${({ y }) => y}px;
  font-weight: 600;
  font-size: 10px;
  display: ${({ show }) => show ? 'flex' : 'none'};
  justify-content:center;
  align-items:center;
  line-height:10px;
`

export const Relative = styled.div`
  /* border:3px dashed green; */
  position:relative;
  /* top:50%; */
  align-items:center;
  justify-content:center;
  display:flex;
`