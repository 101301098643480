import { Track }                from './Filter.Controls.Interval.Track'
import { Mark }                 from './Filter.Controls.Interval.Mark'
import { Thumb }                from './Filter.Controls.Interval.Thumb'
import { Range, Values, Value } from './Filter.Controls.Interval.Slider.style'
import { format }               from 'utilities/utility.filter'

export const Slider = ({value,step,max,min,click,change,after,before,dv,pv,pf,name}) => {

  const vleft  = format( value[0], pv, pf, name )
  const vright = format( value[1], pv, pf, name )

  const range = {
    renderTrack   : Track,
    renderThumb   : Thumb,
    renderMark    : Mark,
    value         : value,
    defaultValue  : dv,
    step          : step,
    max           : max,
    min           : min,
    onSliderClick : click,
    onChange      : change,
    onAfterChange : after,
    onBeforeChange: before,
    marks         : [min,max],
    minDistance   : 0,
    pearling      : true,
  }

  return (
    <>
      <Range {...range} />
      <Values>
        <Value left> { vleft }</Value>
        <Value right>{ vright }</Value>
      </Values>
    </>
  )
}

