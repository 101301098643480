import React   from "react";
import { Box } from "@mui/material";
import { Compare } from 'components/Compare2/Compare'

export const ViewCompare = () => {
  return (
    <>
      <Box sx={{ pb: {xs:"0px", md: "62px" } }}>
        <Compare />
      </Box>
    </>
  );
};
