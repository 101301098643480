import styled            from 'styled-components/macro'
import { static_assets } from 'state/store.global'
import { useAtomValue }  from 'state/jotai'
import { BREAK }         from 'constants'

export const Logo = ({ link = null, device }) => {

  const assets = useAtomValue(static_assets)
  const obj    = assets[`logo_${device}`]

  return (
    <>
      <A    href   = {link}>
      <Wrap device = {device}>
      <Img  src    = {obj.src} alt = '' device = {device} />
        </Wrap>
      </A>
    </>
  )
}

export const Img = styled.img`
    /* border:1px solid black; */
  display: block;
  width  : 100%;
  height : 90px;
  ${ BREAK.md_dn('height: 50px;') }
    /* width:unset; */
    /* height:unset; */
`

export const Wrap = styled.div`
    /* border:5px solid transparent; */
  display      : flex;
  padding-left : 10px;
  padding-right: 10px;
    /* border: 1px solid black; */
  height         : 100%;
  width          : 100%;
  justify-content: center;
  align-items    : center;
`
export const A = styled.a`
    /* border: 1px solid pink; */
    /* display: inline-block; */
  width : 100%;
  height: 100%;
    /* display: flex; */
    /* justify-content:center; */
    /* height: 100%; */
    /* width: 150px; */
`