import styled           from 'styled-components/macro'
import { MENU, BREAK_ } from 'constants'

export const FullWidth = styled.div`
  position: fixed;
  bottom  : 0;
  left    : 100px;
  width   : 100%;
  z-index : 10;
  ${BREAK_.md_up} {
    margin-left: ${({ nav, left }) =>
      (nav && MENU.DESKTOP_NAV_DRAWER_W) +
      (left && MENU.DESKTOP_FILTER_DRAWER_W)}px;
    width: ${({ nav, left, right }) =>
      `calc(100% - ${
        MENU.DESKTOP_NAV_W +
        (nav && MENU.DESKTOP_NAV_DRAWER_W) +
        (left && MENU.DESKTOP_FILTER_DRAWER_W) +
        (right && MENU.DESKTOP_UNIT_DRAWER_W)
      }px)`};
  };
  ${BREAK_.md_dn} {
    left : 0;
    width: 100%;
  }
  transition: padding-left 0.3s ease, padding-right 0.3s ease,
    margin-top 0.3s ease, margin-left 0.3s ease, height 0.3s ease,
    width 0.3s ease;
`

export const Box = styled.div`
  position       : relative;
  bottom         : 0;
  left           : 50%;
  display        : flex;
  justify-content: center;
  align-items    : center;
  width          : 390px;
  height         : 63px;
  color          : ${({ clr }) => clr.primary_text};
  font-size      : 1.125rem;
  transform      : translate(-50%, 0);
  ${BREAK_.md_dn} {
    font-size    : 1rem;
    width        : 100%;
    border-radius: 0;
  }
`

export const Splitter = styled.div`
  height          : calc(100% - 15px);
  width           : 2px;
  background-color: lightgray;
  border-radius   : 99px;
`

export const Section = styled.div`
  display         : flex;
  position       : relative;
  background-color: ${({ clr }) => clr?.primary};
  flex           : 1;
  color          : '#fff';
  height         : 100%;
  justify-content: center;
  align-items    : center;
  padding        : 0px 20px;
  gap            : 10px;
  line-height    : 1;
  cursor         : pointer;
  user-select    : none;
  opacity        : ${({ clr, active }) => active ? clr?.primary : '0.9' };
  &:before {
    position: absolute;
    content: "";
    background: ${({ active }) => active ? '#000' : 'transparent' };
    border-radius: ${({ borderRadius }) => borderRadius };
    width: 99%;
    height: 3px;
    ${({ position }) => position }: 0px;
    bottom: 3px;
    ${BREAK_.md_dn} {
      height: 2px;
      bottom: 1px;
    }
  }
`