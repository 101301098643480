import styled from "styled-components/macro";
import { MENU, BREAK_ } from "constants";
import { sb } from "styles/styles.scrollbar";

export const Content = styled.main`
  height: calc(100% - 59px);
  // height: calc(100% - 120px);
  // ${({ styles }) => styles}

  ${BREAK_.md_dn} {
    // height: calc(100% - 119px);
    // margin-top: 119px;
    ${({ styles }) => styles}/* border:10px solid lightgreen; */
  }
  /* width: 100%; */
  /* overflow:hidden; */
  /* display: flex; */
  /* position: relative; */
  /* border: 15px solid lightgreen; */
  margin-top: 59px;
  overflow: auto;
  ${BREAK_.md_up} {
    padding-right: ${({ right }) => (right ? MENU.DESKTOP_UNIT_DRAWER_W : 0)}px;
    padding-left: ${({ left }) => (left ? MENU.DESKTOP_FILTER_DRAWER_W : 0)}px;
    margin-left: ${({ nav }) =>
      nav ? MENU.DESKTOP_NAV_DRAWER_W + 100 : 100}px;
  }
  /* ${BREAK_.md_dn} {
    margin-top: ${({ y }) => (y > 30 ? 0 : `${MENU.MOBILE_NAV_H}px`)};
    height: ${({ y }) =>
    y > 30 ? "100%" : `calc(100% - ${MENU.MOBILE_NAV_H}px )`};
  }; */
  /* z-index:-1; */
  transition: padding-left 0.3s ease, padding-right 0.3s ease,
    margin-top 0.3s ease, margin-left 0.3s ease, height 0.3s ease,
    width 0.3s ease;
`;

export const Root = styled.div`
  /* display: flex; */
  /* flex-direction: row;
${BREAK_.md_dn} {
  flex-direction: column;
} */
  isolation: isolate;
  width: 100%;
  height: 100%;
  overflow-y: scroll;
  ${sb.hide};
  /* border: 10px solid yellow; */
  /* overflow:hidden; */
  /* position: relative; */
`;
